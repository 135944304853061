import React from "react";
import Filter from "./Filter/Filter";
import SearchBar from "../../components/SearchBar/SearchBar";
import {CircularProgress } from "@mui/material";
import * as c from '../../utils/constants/constants';
import * as p from '../../utils/parameter/parameter';
import * as msg from "../../utils/constants/messages";
import api from "../../utils/API/api";
import { forceLogout } from "../../commonFunctions/forceLogout";
import { ConvertLocalToUtc, GetDefaultDate } from "../../commonFunctions/commonFunctions";
import DocumentLogTable from './LogTable/DocumentLogTable/DocumentLogTable'; 
import UserLogTable from "./LogTable/UserLogTable/UserLogTable";
import ExportDocumentAudit from "./ExportReport/ExportDocumentAuditReport";
import ExportUserAudit from "./ExportReport/ExportUserAuditReport"
import "./AuditReport.css";

class AuditReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      audit_log: [],
      child_audit_log:[],
      audit_log_export: [],
      audit_user_log:[],
      startRecordNum: 0,
      recordsPerPage: 15,
      suggest_box: "",
      is_search: false,
      selectedComponent:"document",
      documentTableHeadData: [
        { name: "Document Name", order: true, icon: false },
        { name: "Document Old Name", order: true, icon: false },
        { name: "Location", order: true, icon: false },
        { name: "Sub Category", order: true, icon: false },
        { name: "Received on", order: true, icon: false },
        { name: "Last Updated on", order: true, icon: false },
      ],
      userTableHeadData : [
        { name: "User Name", order: true, icon: false },
        { name: "Action", order: true, icon: false },
        { name: "Field", order: true, icon: false },
        { name: "Previous Value", order: true, icon: false },
        { name: "Current Value", order: true, icon: false },
        { name: "User Edited Record", order: true, icon: false },
        { name: "Updated on", order: true, icon: false },
      ],
      audit_log_length: 0,
      is_changed: false,
      openSubTable: {},
      search_val: '',
      refresh_list: false, //overlay loader applies whenever any filter is applied on screen 
      isLoading: true, //loader applies on initial load of data
      /*this object contains all filter fields, entire object is retained and filter is passed to endpoint to fetch results*/
      audit_filter: {
        pageNum: 0,
        childPageNumber: 0,
        user_name: "",
        fromDate: "",
        toDate: "",
        sort_order: false,
        sort_key: "Updated on",
        search: "",
        action: "",
        component:'document'
      }
    }
    this.csvLinkEl = React.createRef();
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount = () => {
    document.title = c.PRODUCT_NAME + ' - Audit';
    let defaultAuditDate = GetDefaultDate({ duration: 4, type: "day", format: "MM/DD/YYYY" });
    this.setState({
      audit_filter: {
        ...this.state.audit_filter,
        fromDate: defaultAuditDate.fromDate,
        toDate: defaultAuditDate.toDate,
        appliedFromDate: defaultAuditDate.fromDate,
        appliedToDate: defaultAuditDate.toDate,
        component: 'document'
      }
    }, () => {
      this.fetchResults(this.state.audit_filter);
    });
  }
  
  //calls whenever page num is changed, this updated pageNum state with new page and calls api 
  handleChangePage = (event, newPage) => {
    let recordsPerPage = this.state.recordsPerPage;
    this.setState({
      openSubTable: {},
      child_audit_log: [],
      childTableOpened:null
  });
    this.setState({ audit_filter: { ...this.state.audit_filter, pageNum: newPage } }, () => {
      this.fetchResults(this.state.audit_filter);
    });
  };

  handleChildPageChange = (event, newPage) => {
    this.setState({ audit_filter: { ...this.state.audit_filter, childPageNumber: newPage } });
  };
  //calls whenever any filter field is changed
  handleChange = (event, value) => {
    let col = typeof event == "string" ? event : event.target.name;
    value = value || event.target.value;
    this.setState({ is_changed: true });
    this.setState({
      audit_filter: {
        ...this.state.audit_filter,
        [col]: (col == "fromDate" || col == "toDate") && value == "" ? this.state.audit_filter[col] : value
      }
    });
    //debouncing logic for searchbar, call api only if difference in user input is 500 ms
    if (col === "search") {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.findData("suggest");
      }, 500);
    }
    if (col === 'component') {
      this.setState(prevState => ({
        audit_filter: {
          ...prevState.audit_filter,
          action: ''
        }
      }));
    }
  };
  //calls when apply button is clicked. 
  handleSubmit = () => {

    this.setState({
      openSubTable: {},
      child_audit_log: [],
      childTableOpened:null
  });
    if (this.state.is_changed) {
      this.setState({selectedComponent:this.state.audit_filter?.component})
      this.setState({
        audit_filter: {
          ...this.state.audit_filter,
          pageNum: 0,
          appliedFromDate: this.state.audit_filter.fromDate,
          appliedToDate: this.state.audit_filter.toDate,
          sort_order:'',
          sort_key:''
        }
      }, () => {
        this.fetchResults(this.state.audit_filter);
      });
    }
  };
  //calls when cancel button is clicked. 
  cancelFilter = (modal) => {
    //if cross icon in searchbar is cliked
    if (modal && modal === 'search') {
      this.setState({ audit_filter: { ...this.state.audit_filter, pageNum: 0, search: "",component:'document' }, suggest_box: [], is_search: false,openSubTable: {},childTableOpened:null,
      child_audit_log: [] }, () => {
        this.fetchResults(this.state.audit_filter);
      });
    }
    else {
      let defaultAuditDate = GetDefaultDate({ duration: 4, type: "day", format: "MM/DD/YYYY" });
      this.setState({
        audit_filter: {
          user_name: "",
          action: "",
          fromDate: this.state.audit_filter.appliedFromDate,
          toDate: this.state.audit_filter.appliedToDate,
          appliedFromDate: defaultAuditDate.fromDate,
          appliedToDate: defaultAuditDate.toDate,
          pageNum: 0,
          search: "",
          sort_key: "Updated on",
          sort_order: false,
          component:this.state.audit_filter.component
        },
        is_changed: false,
        suggest_box: [],
        openSubTable: {},
      child_audit_log: [],
      childTableOpened:null
      },
        () => {
          this.fetchResults(this.state.audit_filter);
        }
      );
    }
  };
  //api call to backend
  fetchResults = (audit_filter) => {
    //turn ON overlay loader
    this.setState({ refresh_list: true });
    let from_date = audit_filter.appliedFromDate ? ConvertLocalToUtc({ date: audit_filter.appliedFromDate, format: "MM-DD-YYYY HH:mm:ss", startOf: "day" }) : "";
    let to_date = audit_filter.appliedToDate ? ConvertLocalToUtc({ date: audit_filter.appliedToDate, format: "MM-DD-YYYY HH:mm:ss", endOf: "day" }) : "";
    var query = {
      user_name: audit_filter.user_name && audit_filter.user_name,
      action_name: audit_filter.action && audit_filter.action.replaceAll('_', " "),
      from_date: from_date,
      to_date: to_date,
      component:audit_filter.component,
      search_string: audit_filter.search && audit_filter.search,
      sorting: {
        sort_column: audit_filter.sort_key && audit_filter.sort_key,
        is_asc: audit_filter.sort_order && audit_filter.sort_order,
      }
    }
    if (this.state.audit_filter.component=="document") {
    api.getAuditLogsForDocument(query, audit_filter.pageNum, this.state.recordsPerPage).then((response) => {
      const data = response.data.data;
      if (response.status === 200) {
        const filteredData = data.map((dt) => {
          return {
            ...dt,
          };
        });
        this.setState({
          audit_log: filteredData,
          refresh_list: false,
          audit_filter: {
            ...this.state.audit_filter,
            fromDate: audit_filter.appliedFromDate,
            toDate: audit_filter.appliedToDate,
          },
          isLoading: false
        });
        if (response.data.total_records !== "")
          this.setState({ audit_log_length: parseInt(response.data.total_records) });
      }
    }).catch((error) => {
      if (error.response.status === 401) {
        forceLogout();
      }
      else {
        this.setState({ refresh_list: false });
        window.alert(msg.api_error);
      }

    })}
    if (this.state.audit_filter.component=="user") {
      api.getAuditLogsForUser(query, audit_filter.pageNum, this.state.recordsPerPage).then((response) => {
        const data = response.data.data;
        if (response.status === 200) {
          const filteredData = data.map((dt) => {
            return {
              ...dt,
            };
          });
          this.setState({
            audit_user_log: filteredData,
            refresh_list: false,
            audit_filter: {
              ...this.state.audit_filter,
              fromDate: audit_filter.appliedFromDate,
              toDate: audit_filter.appliedToDate,
            },
            isLoading: false
          });
          if (response.data.total_records !== "")
            this.setState({ audit_log_length: parseInt(response.data.total_records) });
        }
      }).catch((error) => {
        if (error.response.status === 401) {
          forceLogout();
        }
        else {
          this.setState({ refresh_list: false });
          window.alert(msg.api_error);
        }
  
      })}
    
    ;}

  nestedAuditLog = (doc_id) => {

    let audit_filter = this.state.audit_filter

    this.setState({ refresh_list: true });
    let from_date = audit_filter.appliedFromDate ? ConvertLocalToUtc({ date: audit_filter.appliedFromDate, format: "MM-DD-YYYY HH:mm:ss", startOf: "day" }) : "";
    let to_date = audit_filter.appliedToDate ? ConvertLocalToUtc({ date: audit_filter.appliedToDate, format: "MM-DD-YYYY HH:mm:ss", endOf: "day" }) : "";
    var query = {
      user_name: audit_filter.user_name && audit_filter.user_name,
      action_name: audit_filter.action && audit_filter.action.replaceAll('_', " "),
      from_date: from_date,
      to_date: to_date,
      doc_id: doc_id,
      search_string: audit_filter.search && audit_filter.search,
      sorting: {
        sort_column: audit_filter.sort_key && audit_filter.sort_key,
        is_asc: audit_filter.sort_order && audit_filter.sort_order,
      }
    }


    api.fetch_results_audit(query).then((response) => {
      const data = response.data.data;
      if (response.status === 200) {
        const filteredData = data.map((dt) => {
          return {
            ...dt,
          };
        });
        this.setState({
          child_audit_log:  filteredData,
          childTableOpened: doc_id,
          child_data_length: parseInt(response.data.total_records),
          refresh_list: false,
          audit_filter: {
            ...this.state.audit_filter,
            fromDate: audit_filter.appliedFromDate,
            toDate: audit_filter.appliedToDate,
            childPageNumber: 0,
          },
          isLoading: false
        });
      }
    })
  }
  
  findData = (modal, search_val) => {
    /*================alters table data based on searched text==========*/
    if (modal === "results") {
      this.setState({ is_search: true, suggest_box: [] });
      this.setState({ audit_filter: { ...this.state.audit_filter, search: search_val, pageNum: 0 } }, () => {
        this.fetchResults(this.state.audit_filter);
      });
    } else {
      /*=================displays suggestion box based on searched txt==============*/
      var suggest = [];
      let query = {
        search_string: this.state.audit_filter.search
      }
      if (this.state.audit_filter.search.length > 2) {
        this.setState({ is_search: false });
        api.search_suggest_results_audit(query).then((response) => {
          if (response.status === 200) {
            response.data.data && response.data.data.map((data, i) => {
              suggest.push(data);
            })
            this.setState({ suggest_box: suggest });
          }
        }).catch((error) => {
          if (error.response.status === 401) {
            forceLogout();
          } else {
            this.setState({ refresh_list: false });
            window.alert(msg.api_error);
          }
        });
      } else {
        suggest = [];
        this.setState({ suggest_box: suggest, is_search: true });
      }
    }
  };
  //sorts table based table header 
  sort = (key,type) => {
    var sort_order = "";
    if (type == 'document') {
      let table_head = [...this.state.documentTableHeadData];
      for (var i = 0; i < table_head.length; i++) {
        if (key.replace("_", " ") === table_head[i].name) {
          table_head[i].order = !table_head[i].order;
          sort_order = table_head[i].order;
          table_head[i].icon = true;
        } else table_head[i].icon = false;
      }
      this.setState({ documentTableHeadData: table_head, audit_filter: { ...this.state.audit_filter, sort_order: sort_order, sort_key: key, pageNum: 0 } }, () => {
        this.fetchResults(this.state.audit_filter);
      });
    }
    if (type == 'user') {
      let table_head = [...this.state.userTableHeadData];
      for (var i = 0; i < table_head.length; i++) {
        if (key !== "Field" && key !== "Previous Value" && key !== "Current Value" && key !== "User Edited Record" && key.replace("_", " ") === table_head[i].name) {
          table_head[i].order = !table_head[i].order;
          sort_order = table_head[i].order;
          table_head[i].icon = true;
        } else table_head[i].icon = false;
      }
      if (key == "Field" || key == "Previous Value" || key == "Current Value" || key == "User Edited Record") return 0;
      this.setState({ userTableHeadData: table_head, audit_filter: { ...this.state.audit_filter, sort_order: sort_order, sort_key: key, pageNum: 0 } }, () => {
        this.fetchResults(this.state.audit_filter);
      })
    }
  };
  //takes to doc details screen
  goToIntervention = (doc_id) => {
    //making it false for the purpose of retaining filter from doc mgmt screen and doc details
    p.isFilter.isFilter = false;
    localStorage.setItem('is_filter', JSON.stringify(p.isFilter));
    localStorage.setItem(c.DOC_ID, doc_id);
    var _props = this.props;
    _props.history.push(`/${c.PAGE_URLS[c.PAGE_DATA]}/${c.PAGE_URLS[c.PAGE_DOC_MGMT]}/${c.PAGE_URLS[c.PAGE_MANUAL_REVIEW]}?doc_id=${localStorage.getItem(c.DOC_ID)}`);
    localStorage.removeItem(c.PREV_ROUTE);
  };
  //API call for export as csv. Fetches entire data without pagination but with applied filters
  generateReport = () => {
    this.setState({ refresh_list: true });
    let audit_filter = this.state.audit_filter;
    let from_date = audit_filter.fromDate ? ConvertLocalToUtc({ date: audit_filter.fromDate, format: "MM-DD-YYYY HH:mm:ss", startOf: "day" }) : "";
    let to_date = audit_filter.toDate ? ConvertLocalToUtc({ date: audit_filter.toDate, format: "MM-DD-YYYY HH:mm:ss", endOf: "day" }) : "";
    var query = {
      user_name: audit_filter.user_name && audit_filter.user_name,
      action_name: audit_filter.action && audit_filter.action.replaceAll('_', " "),
      from_date: from_date,
      to_date: to_date,
      search_string: audit_filter.search && audit_filter.search,
      component_name:this.state.selectedComponent,
      sorting: {
        sort_column: audit_filter.sort_key && audit_filter.sort_key,
        is_asc: audit_filter.sort_order && audit_filter.sort_order,
      }
    }
    api.fetch_results_audit(query, '0', '5000').then((response) => {
      const data = response.data.data;
      if (response.status === 200) {
        this.setState({ audit_log_export: data, refresh_list: false, isLoading: false }, () => {
          setTimeout(() => {
            this.csvLinkEl.current.link.click();
          });
        });

      }
    }).catch((error) => {
      if (error.response.status === 401) {
        forceLogout();
      }
      else {
        this.setState({ refresh_list: false });
        window.alert(msg.api_error);
      }

    });
  }

  
  toggleSubTable = (id, open) => {
    if (open == true) {
      id && this.nestedAuditLog(id);
    } else {
        this.setState(({
          child_audit_log: [],
          childTableOpened: null
        }))
    }
};

  handletab = (value) =>{
    this.setState({component:value})
  }

  render() {
    return (
      <>
        {this.state.isLoading ?
          <CircularProgress style={{ color: "grey", margin: "40vh auto" }} />
          :
          <>
            {this.state.refresh_list &&
              <CircularProgress size={30} className="common-spinner"
              />}
            <div className={this.state.refresh_list ? "block-area-common" : ""}>
              <div className="audit-log">
                <div className="titlebox audit-flex">
                  <p className="page-title">Audit Report</p>
                  {this.state.selectedComponent == 'document' ?
                    <ExportDocumentAudit
                      audit_data={this.state.audit_log_export}
                      audit_filter={this.state.audit_filter}
                      generateReport={this.generateReport}
                      csvLinkEl={this.csvLinkEl}
                    /> : null}
                      {this.state.selectedComponent == 'user' ?
                    <ExportUserAudit
                      audit_data={this.state.audit_log_export}
                      audit_filter={this.state.audit_filter}
                      generateReport={this.generateReport}
                      csvLinkEl={this.csvLinkEl}
                    /> : null}
                </div>
                <div className='audit-flex'>
                  <Filter
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    cancelFilter={this.cancelFilter}
                    audit_filter={this.state.audit_filter}
                  />
                  <div>
                    <SearchBar
                      handleChange={this.handleChange}
                      findData={this.findData}
                      suggest_box={this.state.suggest_box}
                      cancelFilter={this.cancelFilter}
                      is_search={this.state.is_search}
                      search={this.state.audit_filter.search}
                    />
                  </div>
                </div>
                {this.state.selectedComponent && this.state.selectedComponent == 'document' ?
                  <DocumentLogTable
                    audit_data={this.state.audit_log && this.state.audit_log}
                    child_audit_log={this.state.child_audit_log}
                    childTableOpened={this.state.childTableOpened}
                    goToIntervention={this.goToIntervention}
                    sort={this.sort}
                    toggleSubTable={this.toggleSubTable}
                    handleChangePage={this.handleChangePage}
                    handleChildPageChange={this.handleChildPageChange}
                    documentTableHeadData={this.state.documentTableHeadData}
                    audit_filter={this.state.audit_filter}
                    audit_log_length={this.state.audit_log_length}
                    child_data_length={this.state.child_data_length}
                    recordsPerPage={this.state.recordsPerPage}
                    pageNum={this.state.audit_filter.pageNum}
                    childPageNumber={this.state.audit_filter.childPageNumber}
                    refresh_list={this.state.refresh_list}
                  /> : null}
                  {this.state.selectedComponent && this.state.selectedComponent == "user" ?
                  <UserLogTable
                    userTableHeadData={this.state.userTableHeadData}
                    audit_filter={this.state.audit_filter}
                    audit_data={this.state.audit_user_log && this.state.audit_user_log}
                    audit_log_length={this.state.audit_log_length}
                    recordsPerPage={this.state.recordsPerPage}
                    pageNum={this.state.audit_filter.pageNum}
                    refresh_list={this.state.refresh_list}
                    handleChangePage={this.handleChangePage}
                    sort={this.sort}
                  /> : null}
              </div>
            </div>
          </>
        }
      </>
    );
  }
}


export default AuditReport;

